exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adobe-js": () => import("./../../../src/pages/adobe.js" /* webpackChunkName: "component---src-pages-adobe-js" */),
  "component---src-pages-codetwo-js": () => import("./../../../src/pages/codetwo.js" /* webpackChunkName: "component---src-pages-codetwo-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microsoft-js": () => import("./../../../src/pages/microsoft.js" /* webpackChunkName: "component---src-pages-microsoft-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

